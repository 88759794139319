// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "node_modules/trumbowyg/dist/ui/sass/trumbowyg";
.hamby {
    width: 20px;
    display: block;
    float: left;
    margin-right: 6px;
    vertical-align: middle;
    margin-top: -2px;
    cursor: pointer;
    opacity: 1;
}
